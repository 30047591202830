import { IMAGE_EXTENSIONS, SOUND_EXTENSIONS, VIDEO_EXTENSIONS } from "constants/FileExtensions";
import { ID, MediaDataV2 } from "models";
import getFileExtensionFromPath from "utils/getFileExtensionFromPath";
import { useCurrentBrowser } from "../../../../utils/useCurrentBrowser";

const Body = ({
    item,
    creativeId,
    onLoad
  }: {
  item: MediaDataV2;
  creativeId?: ID;
  onLoad: () => void;
}) => {
  const {isSafari, isIE} = useCurrentBrowser();
  const isDisplayTiff = isIE || isSafari;

  if (item) {
    const extension = getFileExtensionFromPath(item.file_path || "");
    const isTiff = extension === '.tiff';

    if (extension && creativeId) {
      const src = `/api/v1/creative/${creativeId}/media/${item.id}/`;
      if (IMAGE_EXTENSIONS.includes(extension)) {
        if ((isTiff && isDisplayTiff) || !isTiff) {
          return (
            <img
              src={src}
              alt={item.file_path}
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
              onLoad={onLoad}
            />
          );
        } else {
          return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
              <p>Похоже, файл этого типа не отображается в вашем браузере. Чтобы увидеть загруженное изображение, скачайте файл.</p>
            </div>
          )
        }
      }

      if (VIDEO_EXTENSIONS.includes(extension)) {
        onLoad();
        return (
          <video controls style={{ objectFit: "contain", height: "100%", width: "100%" }}>
            <source src={src} />
          </video>
        );
      }

      if (SOUND_EXTENSIONS.includes(extension)) {
        onLoad();
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <audio controls src={src} style={{ width: "100%" }} />
          </div>
        );
      }
    }
  }

  onLoad();

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
      <p>Предпросмотр недоступен</p>
    </div>
  );
};

export default Body;
