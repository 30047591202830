import { uploadFile } from "api/upload_file";
import { Control, Controller, FieldValues, useForm, UseFormWatch } from "react-hook-form";
import { FaPlus } from "react-icons/fa6";
import { TAttachment } from "types/AttachmentsTypes";
import { DropZoneMultipleField } from "ui/DropZoneMultiple";
import IconButton from "ui/IconButton";
import { InputField } from "ui/Input";
import { TextAreaField } from "ui/TextArea";
import getByteToGigabyte from "utils/getByteToGigabyte";
import { v4 as uuidv4 } from "uuid";
import { TCardBodyElemntProps, TTextForm } from "../../types";
import {ReactNode} from "react";

const CreativeFormCardBody = ({
  isLoading,
  isError,
  selectedFields,
  selectedFileExtensions,
  control,
  errors,
  clearErrors,
  watch,
  creativeLoading,
  onFormChange,
  setValue
}: TCardBodyElemntProps) => {
  const {
    control: textControl,
    watch: textWatch,
    setValue: textSetValue,
    formState: { errors: textErrors }
  } = useForm({
    defaultValues: {
      text: ""
    } as TTextForm
  });

  const newText = textWatch("text");

  const onTextAdd = () => {
    if (newText) {
      const previoslyAttached = watch("attachments") as TAttachment[];
      const newTextAttachment: TAttachment = {
        tempId: uuidv4(),
        type: "text",
        text: newText
      };
      setValue(
        "attachments",
        previoslyAttached ? [...previoslyAttached, newTextAttachment] : [newTextAttachment]
      );
      clearErrors("attachments");

      textSetValue("text", "");
      if (onFormChange) onFormChange();
    }
  };

  if (isLoading) return <p>Загрузка...</p>;

  if (isError) return <p>Произошла ошибка загрузки</p>;

  if (!selectedFields) return <></>;

  return (
    <>
      {selectedFields.includes("text_data") ? (
        <div className="row align-items-center">
          <div className="col-sm-9">
            <TextAreaField
              name="text"
              title="Текстовые данные креатива"
              control={textControl as unknown as Control<FieldValues, any>}
              errors={textErrors}
              maxLength={65000}
              extraAction={onFormChange}
            />
          </div>
          <div className="col-sm-3">
            <IconButton
              isFullWidth
              color="secondary"
              text="Добавить"
              disabled={!newText || !newText.length}
              icon={<FaPlus size={14} /> as ReactNode}
              onClick={onTextAdd}
            />
          </div>
        </div>
      ) : null}

      {selectedFields.includes("attachments") ? (
        <div className="row">
          <div className="col-sm-12">
            <DropZoneMultipleField
              uploadFunction={uploadFile}
              name="attachments"
              title="Прикрепить файлы"
              message="Выберите файлы, которые хотите загрузить, либо перетащите их в данную область."
              control={control as unknown as Control<FieldValues, any>}
              watch={watch as unknown as UseFormWatch<FieldValues>}
              errors={errors}
              disabled={creativeLoading}
              fileExtensions={selectedFileExtensions}
              isRequired
              defaultDescription={watch("media_description") as string}
              validate={(value: TAttachment[] | undefined) => {
                if (value) {
                  const sumWeight = getByteToGigabyte(
                    value.reduce((prev, f) => prev + (f.file?.size || f.file_size || 0), 0)
                  );
                  if (sumWeight > 1) {
                    return `Суммарный вес прикрепленных файлов слишком большой (${
                      Math.round(sumWeight * 100) / 100
                    }Gb). Перед сохранением необходимо удалить несколько файлов, чтобы суммарный вес не превышал 1Gb.`;
                  }
                }
                return true;
              }}
            />
          </div>
        </div>
      ) : (
        <Controller
          control={control as unknown as Control<FieldValues, any>}
          name="attachments"
          rules={{ required: true }}
          render={() => <></>}
        />
      )}
    </>
  );
};

export default CreativeFormCardBody;
