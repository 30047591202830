/* eslint-disable no-unused-vars */
import {
  IMAGE_EXTENSIONS,
  SOUND_EXTENSIONS,
  VIDEO_EXTENSIONS,
  ZIP_EXTENSIONS
} from "constants/FileExtensions";
import { TFieldVariants } from "types/CreativesTypes";
import creative_form from 'dictionaries/creative_form.json';
import { CreativeFormKeys } from "types/keys";

export const creativesFormsObject = creative_form.reduce((prev, next) => {
  return next.active ? {...prev, [next.id]: next.title} : {...prev}
}, {})

export const FILE_EXTENSIONS: {
  [_ in CreativeFormKeys]: string[] | undefined;
} = {
  // Баннер
  2: IMAGE_EXTENSIONS,
  // Текстовый блок
  3: undefined,
  // Текстово-графический блок
  4: IMAGE_EXTENSIONS,
  // Видеоролик
  5: VIDEO_EXTENSIONS,
  // Аудиозапись
  6: SOUND_EXTENSIONS,
  // Аудиотрансляция в прямом эфире
  7: SOUND_EXTENSIONS,
  // Видеотрансляция в прямом эфире
  8: VIDEO_EXTENSIONS,
  // Текстово-графический блок с видео,
  9: [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS],
  // Текстово-графический блок с аудио,
  10: [...IMAGE_EXTENSIONS, ...SOUND_EXTENSIONS],
  // Текстово-графический блок с аудио и видео,
  11: [...IMAGE_EXTENSIONS, ...VIDEO_EXTENSIONS, ...SOUND_EXTENSIONS],
  // Текстовый блок с видео,
  12: VIDEO_EXTENSIONS,
  // Текстовый блок с аудио,
  13: SOUND_EXTENSIONS,
  // Текстовый блок с аудио и видео,
  14: [...VIDEO_EXTENSIONS, ...SOUND_EXTENSIONS],
  // HTML5-баннер
  15: ZIP_EXTENSIONS
};

// "media_description" - Описание изображения креатива
// "text_data" - Текстовые данные креатива
// "creative_per_file_word" - Способ получения токена
// "attachments" - Прикрепить файлы

export const DISPLAY_FIELDS: {
  [_ in CreativeFormKeys]: TFieldVariants[];
} = {
  // Баннер
  2: ["creative_per_file_word", "attachments"],
  // Текстовый блок
  3: ["text_data"],
  // Текстово-графический блок
  4: ["text_data", "attachments", "media_description"],
  // Видеоролик
  5: ["creative_per_file_word", "attachments"],
  // Аудиозапись
  6: ["creative_per_file_word", "attachments"],
  // Аудиотрансляция в прямом эфире
  7: ["creative_per_file_word", "attachments"],
  // Видеотрансляция в прямом эфире
  8: ["creative_per_file_word", "attachments"],
  // Текстово-графический блок с видео,
  9: ["text_data", "attachments", "media_description"],
  // Текстово-графический блок с аудио,
  10: ["text_data", "attachments", "media_description"],
  // Текстово-графический блок с аудио и видео,
  11: ["text_data", "attachments", "media_description"],
  // Текстовый блок с видео,
  12: ["text_data", "attachments"],
  // Текстовый блок с аудио,
  13: ["text_data", "attachments"],
  // Текстовый блок с аудио и видео,
  14: ["text_data", "attachments"],
  // HTML5-баннер
  15: ["creative_per_file_word", "attachments"]
};

export const TOKEN_VARIANTS_SUBTITLES = {
  false:
    "Вы можете выбрать и загрузить один или несколько файлов с Вашего компьютера, удерживая клавишу Ctrl (или Shift). При этом каждому загруженному файлу будет назначен один (единый) токен. Например, Вы можете загрузить баннер и пакет ресайзов для него.",
  true: "Вы можете выбрать и загрузить один или несколько файлов с Вашего компьютера, удерживая клавишу Ctrl (или Shift). При этом каждому загруженному файлу будет назначен отдельный токен. Например, Вы можете загрузить несколько разных баннеров для одного товара/услуги, каждому изображению будет присвоен свой уникальный токен."
};
