import { ID } from "models";
import { ReactNode } from "react";
import { Input } from "reactstrap";
import { TListingItem } from "types/TListingItem";
import { ActionSelector, ActionSelectorProps } from "ui/ActionSelector";

type Props = {
  onRowDoubleClick: () => void;
  onExportIdCheck?: (_: ID) => void;
  exportIds?: ID[];
  id?: ID;
  list: ReactNode;
  actions: (ActionSelectorProps | null)[];
  item?: TListingItem;
};

const ListingTableRow = ({
  onRowDoubleClick,
  onExportIdCheck,
  exportIds,
  id,
  item,
  list,
  actions
}: Props) => (
  <tr onDoubleClick={onRowDoubleClick}>
    {onExportIdCheck && exportIds ? (
      <td>
        <Input
          type="checkbox"
          onChange={() => (id && onExportIdCheck ? onExportIdCheck(+id) : null)}
          checked={id && exportIds ? exportIds.includes(+id) : false}
          disabled={!onExportIdCheck}
        />
      </td>
    ) : null}
    <td scope="row" className="fw-bold">
      {id}
    </td>
    {list}
    {actions ? (
      <ActionSelector actions={actions} id={id} item={item} isDotsVertical={false} />
    ) : null}
  </tr>
);

export default ListingTableRow;
