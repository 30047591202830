import axios from "axios";
import Cookies from "js-cookie";
import { CreativeV2, ID } from "models";

export const putCreativeV2 = ({ id, creative }: { id: ID; creative: CreativeV2 }) => {
  return axios
    .put(`/api/v2/creative/${id}/`, creative, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json;charset=utf-8"
      }
    })
    .then((res) => {
      return res;
    });
};
