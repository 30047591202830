import get from "lodash/get";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { FaXmark } from "react-icons/fa6";
import { FormGroup, Input, InputGroup } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";
import {JSX} from "react";

type Props = {
  name: string;
  title: string;
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  isClearable?: boolean;
  isRequired?: boolean;
  isStared?: boolean;
  pattern?: RegExp;
  errorText?: string;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  isNumber?: boolean;
  extraAction?: (_?: string) => void;
  noMargin?: boolean;
  textEllipsis?: boolean;
};

export const InputField = ({
  name,
  title,
  control,
  errors,
  isClearable,
  isRequired,
  isStared,
  errorText,
  pattern,
  maxLength,
  minLength,
  disabled,
  isNumber = false,
  extraAction,
  noMargin = false,
  textEllipsis = false,
}: Props): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        pattern,
        maxLength,
        minLength
      }}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormGroup noMargin={noMargin}>
          <FieldLabel title={title} isRequired={isRequired || isStared} for={name} />
          <InputGroup>
            <Input
              style={{
                textOverflow: textEllipsis ? 'ellipsis' : 'initial',
                whiteSpace: textEllipsis ? 'nowrap' : 'initial',
                overflow: textEllipsis ? 'hidden' : 'initial',
                paddingRight: textEllipsis ? '20px' : 'initial',
              }}
              id={name}
              placeholder={title}
              value={(value as string) || ""}
              onChange={(e) => {
                onChange(e);
                if (extraAction) extraAction(e.target.value);
              }}
              type="text"
              invalid={!!errors && !!get(errors, name)}
              disabled={disabled}
              onBlur={() => {
                if (value && isNumber) {
                  const newValue = (value as string).replace(/ /g, "").replace(/,/g, ".");
                  onChange(newValue);
                  if (extraAction) extraAction(newValue);
                }
                onBlur();
              }}
              maxLength={maxLength}
            />
            {isClearable && !!value ? (
              <button
                type="button"
                onClick={() => {
                  onChange("");
                  if (extraAction) extraAction("");
                  onBlur();
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  cursor: "default",
                  transition: "color 150ms",
                  color: "hsl(0, 0%, 80%)",
                  border: "none",
                  background: "transparent",
                  paddingInline: "8px"
                }}
              >
                <FaXmark size={16} />
              </button>
            ) : null}
          </InputGroup>
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};
