import { TextDataV2 } from "models";

const BodyTextData = ({
  item,
  onLoad
}: {
  item: TextDataV2;
  onLoad: () => void;
}) => {
  if (item) {
      return <div style={{ whiteSpace: "pre-wrap" }}>{item.text}</div>;
  }

  onLoad();

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
      <p>Предпросмотр недоступен</p>
    </div>
  );
};

export default BodyTextData;
