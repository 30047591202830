import { CreativeGroupV2 } from "models";
import { TCreativeGroupNewFilter } from "types/TCreativeGroupFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

interface IProps extends TSearchParamsPropsNoFilter {
  filter?: TCreativeGroupNewFilter;
}

export const getCreativeGroupV2Info = (props?: IProps): Promise<APIListData<CreativeGroupV2>> => {
  return fetch(
    props === undefined
      ? `/api/v2/creative_group/`
      : `/api/v2/creative_group/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  )
    .then((res) => {
      const result = res.json() as Promise<APIListData<CreativeGroupV2>>;
      return result;
    })
    .then((res) => {
      return res;
    });
};
