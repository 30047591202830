import kktu from "dictionaries/kktu.json";

const isThirdLevel = (value: string): boolean => {
    const dotsCount = (value.split('.') || []).length;
    return !!dotsCount && dotsCount > 2
}

const KKTUOptions = kktu.classifiers_of_product_categories.filter(item => isThirdLevel(item.code)).map((kktu) => ({
    value: kktu.code,
    label: kktu.code + " " + kktu.title
}));

export default KKTUOptions;
