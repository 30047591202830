import { ID } from "models";
import React, { ReactElement, useState } from "react";
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Table } from "reactstrap";
import {IAttachmentOld, TAttachment} from "types/AttachmentsTypes";
import QuestionTooltip from "ui/QuestionTooltip";
import useIsOpenControl from "utils/useIsOpenControl";
import AttachmentPreviewModal, { TDisplayObj } from "./AttachmentPreviewModal";
import FileRow from "./FileRow";
import TextRow from "./TextRow";
import {Control, FieldErrors} from "react-hook-form/dist/types";

type TMiddleColumn = {
  head: string;
  value?: string;
};

export type TOnDeleteFields = {
  id: ID;
};

type Props = {
  attachedFiles: TAttachment[];
  onDelete: (_id: ID) => void;
  middleColumn?: TMiddleColumn;
  firstColumnHead?: string | ReactElement;
  creativeId?: ID;
  watch?: UseFormWatch<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  descriptionFromOutside?: string;
  nameAndKey?: {
    name: string;
    key: string;
  };
  isError?: boolean;
  errorText?: string;
  isNotDraftCreative?: boolean;
  extraAction?: () => void;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

const AttachedFilesTable = ({
  attachedFiles,
  onDelete,
  firstColumnHead,
  creativeId,
  watch,
  setValue,
  descriptionFromOutside,
  nameAndKey,
  isError,
  errorText,
  isNotDraftCreative,
  extraAction,
  control,
  errors,
}: Props) => {
  const { isOpen, toggle } = useIsOpenControl();

  const [displayObj, setDisplayObj] = useState<TDisplayObj | null>(null);

  if ((!attachedFiles || attachedFiles.length === 0) && !isError) return null;

  if (isError)
    return (
      <p className="invalid-feedback text-center" style={{ display: "block" }}>
        {errorText}
      </p>
    );

  const isAnythingLoading = attachedFiles.map(({ fileState }) => fileState).includes("LOADING");

  return (
    <>
      <p className="fs-4 text-center font-weight-bold mb-0 mt-3">Медиаданные креатива</p>
      <Table hover className="table" responsive>
        <thead>
          <tr>
            <th>
              {firstColumnHead || (
                <>
                  Файл
                  <br />
                  Текстовое содержание
                </>
              )}
            </th>
            <th>
              Описание изображения креатива
            </th>
            <th style={{ width: "min-content", textAlign: "center" }}>
              {/* ADVM-373: добавить возможность скачать все прикрепленные файлы */}
              {/* <a href="#">Скачать все</a> */}
              Скачать
            </th>
            <th style={{ width: "min-content", textAlign: "center" }}>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {(attachedFiles as IAttachmentOld[])
            .map(
              (
                { tempId, type, id, data, description, file, fileState, text, file_size, file_path },
                index
              ) => {
                const isRecentlyAttachedFile = !!text || !!file;
                const isAlreadyAttachedFile = !!file_path && !!id;

                if (type === "file") {
                  return (
                    <FileRow
                      key={tempId}
                      tempId={tempId}
                      id={id}
                      data={file_path}
                      description={description}
                      file={file}
                      fileState={fileState}
                      fileSize={file_size}
                      watch={watch}
                      setValue={setValue}
                      index={index}
                      setDisplayObj={setDisplayObj}
                      toggle={toggle}
                      onDelete={onDelete}
                      creativeId={creativeId}
                      descriptionFromOutside={descriptionFromOutside}
                      nameAndKey={nameAndKey}
                      isNotDraftCreative={isNotDraftCreative}
                      extraAction={extraAction}
                      isAnythingLoading={isAnythingLoading}
                      isAlreadyAttachedFile={isAlreadyAttachedFile}
                      isRecentlyAttachedFile={isRecentlyAttachedFile}
                      control={control}
                      errors={errors}
                    />
                  );
                }
                return (
                  <TextRow
                    key={tempId}
                    tempId={tempId}
                    data={data}
                    text={text}
                    setDisplayObj={setDisplayObj}
                    toggle={toggle}
                    onDelete={onDelete}
                    disabledRemove={
                      isAnythingLoading || (isAlreadyAttachedFile && isNotDraftCreative)
                    }
                    isAlreadyAttachedFile={isAlreadyAttachedFile}
                    isRecentlyAttachedFile={isRecentlyAttachedFile}
                  />
                );
              }
            )
            .reverse()}
        </tbody>
      </Table>
      <AttachmentPreviewModal isOpen={isOpen} toggle={toggle} displayObj={displayObj} />
    </>
  );
};

export default AttachedFilesTable;
