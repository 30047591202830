import { TNotVisibleFields } from "utils/onErrorMutation";

export const notVisibleFieldsCreative: TNotVisibleFields = [
  {
    name: "group",
    title: "Список доступных групп креативов"
  },
  {
    name: "label",
    title: "Наименование группы"
  },
  {
    name: "advertisement_type",
    title: "Тип рекламной кампании"
  },
  {
    name: "start_date",
    title: "Дата начала периода размещения"
  },
  {
    name: "end_date",
    title: "Дата окончания периода размещения"
  },
  {
    name: "peculiarities",
    title: "Особенности рекламной кампании"
  },
  // {
  //   name: "eacs",
  //   title: "Коды ОКВЭД"
  // },
  {
    name: "target_audience",
    title: "Параметры целевой аудитории"
  },
  {
    name: "description",
    title: "Общее описание объекта рекламирования"
  },
  {
    name: "final_contract",
    title: "Номер договора"
  },
  {
    name: "advertisement_form",
    title: "Форма распространения"
  }
];

export const KKTU_WITH_REQUIRED_DESCRIPTION = ['30.15.1']
