import { IMAGE_EXTENSIONS, SOUND_EXTENSIONS, VIDEO_EXTENSIONS } from "constants/FileExtensions";
import { TDisplayObj } from ".";
import { useCurrentBrowser } from "../../../utils/useCurrentBrowser";

const Body = ({ displayObj, onLoad }: { displayObj: TDisplayObj; onLoad: () => void }) => {
  const {isSafari, isIE} = useCurrentBrowser();
  const isDisplayTiff = isIE || isSafari;
  if (displayObj.extension) {
    const isTiff = displayObj.extension === '.tiff';
    if (IMAGE_EXTENSIONS.includes(displayObj.extension)) {
      if ((isTiff && isDisplayTiff) || !isTiff) {
      return (
        <img
          src={displayObj.url}
          alt={displayObj.name}
          style={{ objectFit: "contain", height: "100%", width: "100%" }}
          loading="lazy"
          onLoad={onLoad}
        />
      );
    } else {
       return (
         <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
           <p>Похоже, файл этого типа не отображается в вашем браузере. Чтобы увидеть загруженное изображение, скачайте файл.</p>
         </div>
       )
      }
    }

    if (VIDEO_EXTENSIONS.includes(displayObj.extension)) {
      onLoad();
      return (
        <video controls style={{ objectFit: "contain", height: "100%", width: "100%" }}>
          <source src={displayObj.url} />
        </video>
      );
    }

    if (SOUND_EXTENSIONS.includes(displayObj.extension)) {
      onLoad();
      return (
        <audio
          controls
          src={displayObj.url}
          style={{ objectFit: "contain", height: "100%", width: "100%" }}
        />
      );
    }
  } else if (displayObj.text) {
    onLoad();
    return <div style={{ whiteSpace: "pre-wrap" }}>{displayObj.text}</div>;
  }

  return <p>Предпросмотр файла недоступен</p>;
};

export default Body;
