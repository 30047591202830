import { AxiosError } from "axios";
import {CreativeGroupShortV2, CreativeShortV2, TextDataV2} from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { TAttachment } from "types/AttachmentsTypes";
import createNotification from "ui/Notification";
import { onErrorValidate } from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import { CreativeForm } from "./components/form";
import { notVisibleFieldsCreative } from "./constants";
import { onErrorAdd } from "./helpers";
import {ICreativeAddForm, ICreativeAddFormExtraFields} from "./types";
import {addCreativeV2} from "api/creative_v2_add";
import {addCreativeGroupV2} from "api/creative_group_v2_add";
import {CreativeFormKeys, CreativeTypeKeys} from "types/keys";

export const CreativeAddForm = () => {
  const [isDraft, setIsDraft] = useState(false);
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    clearErrors
  } = useForm<ICreativeAddForm>({
    defaultValues: {} as ICreativeAddForm
  });

  const { mutate: addGroup } = useMutation({
    mutationFn: addCreativeGroupV2,
    onSuccess: (p) =>
      onSuccessMutation(
        p,
        `/creative/${creativeLastSearch}`,
        navigate,
        false,
        `Группа креативов и креативы добавлены`
      ),
    onError: (err: AxiosError) => onErrorAdd(err, setError)
  });

  const creatives = watch("extra_fields.creatives");

  const addCreativeNewFunction = async () => {
    const status = isDraft ? '2' : '1';

    const creatives_to_send: CreativeShortV2[] = [];

    (creatives as ICreativeAddFormExtraFields['creatives'] || []).forEach((cr, crIndex) => {
      const advertiser_urls = cr.advertiser_urls?.map(({ advertiser_url }) => ({advertiser_url}));
      const attachments = cr.attachments;
      const texts = attachments
        ? attachments
            .filter((attachment) => attachment.type === "text")
            .map((attachment) => ({ text: attachment.text } as TextDataV2))
        : undefined;
      const medias = attachments
        ? attachments
            .filter((attachment) => attachment.type === "file")
            .map((attachment, index) => ({
              file_path: attachment.filePath,
              description: attachment.description,
            }))
        : undefined;

      creatives_to_send.push({
        advertiser_urls,
        medias,
        texts,
        status
      });
    });

    if (watch("new_group")) {
      const initial_contract = watch("initial_contract");
      const final_contract = watch("final_contract");
      const is_native =
        watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_native";
      const is_social =
        watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_social";

      const group: CreativeGroupShortV2 = {
        label: watch("label") || "",
        start_date: watch("start_date"),
        end_date: watch("end_date"),
        initial_contract: initial_contract ? +initial_contract : undefined,
        final_contract: final_contract ? +final_contract : undefined,
        advertisement_type: String(watch("advertisement_type")) as CreativeTypeKeys,
        advertisement_form: String(watch("advertisement_form")) as CreativeFormKeys,
        description: watch("description"),
        target_audience: watch("target_audience"),
        is_native: is_native,
        is_social: is_social,
        kktu: watch("kktu"),
      };

      addGroup({
        ...group,
        extra_fields: {
          creatives: creatives_to_send
        }
      });
      return;
    }

    const group_id = watch("group_id");

    await Promise.allSettled(creatives_to_send.map((crts) => addCreativeV2({ ...crts, group: group_id })))
      .then((cr) => {
        const allNotifications = cr.map((c) => {
          if (c.status === "fulfilled" && c.value?.notifications) return c.value?.notifications;
          return [];
        });
        allNotifications.forEach((notificationsArray) => {
          notificationsArray.forEach((notification) =>
            createNotification(notification.tags, notification.message)
          );
        });
        return cr;
      })
      .catch((cr) => {
        console.log(cr);
      });

    navigate(`/creative/${creativeLastSearch}`);
    return;
  };

  const onSubmit = handleSubmit(
    () => {
      void addCreativeNewFunction();
    },
    (err) => onErrorValidate(err, notVisibleFieldsCreative)
  );

  const buttonsDisabled = creatives
    ?.reduce((prev, creative) => [...prev, ...(creative?.attachments || [])], [] as TAttachment[])
    .map(({ fileState }) => fileState)
    .includes("LOADING");

  const buttons = {
    toDraft: (
      <Button
        key="draft"
        color="primary"
        outline
        type="submit"
        onClick={() => setIsDraft(true)}
        disabled={buttonsDisabled}
      >
        Сохранить черновик
      </Button>
    ),
    save: (
      <Button
        key="add"
        color="primary"
        type="submit"
        onClick={() => setIsDraft(false)}
        disabled={buttonsDisabled}
      >
        Отправить в ЕРИР
      </Button>
    )
  };

  return (
    <div>
      <h1>Мастер добавления креативов</h1>

      <CreativeForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons}
        watch={watch}
        setValue={setValue}
        clearErrors={clearErrors}
        isDraft={isDraft}
        buttonsDisabled={buttonsDisabled}
      />
    </div>
  );
};
