import React from 'react';
import {FieldProps} from "./types";
import {DatePickerField} from "../../../../ui";

const ExpirationDateField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
    if (!isShown) return null;

    return (
        <div className="row align-items-center">
            <div className="col-sm-9">
                <DatePickerField
                    name="expiration_date"
                    title="Дата окончания срока действия договора"
                    control={control}
                    errors={errors}
                    disabled={contractLoading}
                    returnFormat="yyyy-MM-dd"
                    isRequired={isRequired !== null ? isRequired : false}
                    isStared={isRequired !== null}
                />
            </div>
        </div>
    );
}

export default ExpirationDateField;
