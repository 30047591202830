import Cookies from "js-cookie";
import ListingLayout from "Layout/ListingLayout";
import { Invoice } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setStatisticsLastSearchParams } from "reducers/LastSearchParams";
import { TSearchParamsRequired } from "types/TSearchParams";
import { TStatisticsFilter } from "types/TStatisticsFilter";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getOnlyValidObj from "utils/getOnlyValidObj";
import useIsOpenControl from "utils/useIsOpenControl";
import { StatisticsFilter } from "./components/filter";
import { StatisticsTable } from "./components/table";

const defaultSearchParams: TSearchParamsRequired<TStatisticsFilter> = {
  search: "",
  ordering: "-id",
  page: 0,
  page_size: 10,
  filter: {
    ord_account: undefined,
    combined_status: undefined,
    client_role: undefined,
    contractor_role: undefined,
    client: undefined,
    contractor: undefined
  }
};

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  }
];

export const StatisticsList = () => {
  const navigate = useNavigate();

  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();

  const {
    isLoading: statisticsLoading,
    error: statisticsError
    // data: statisticsInfoList,
    // refetch: statisticsRefetch
  } = useQuery({
    queryKey: [
      "contract_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () => {}
    // getStatisticsList({
    //   page: searchParams.page,
    //   size: searchParams.page_size,
    //   search: searchParams.search,
    //   filter: searchParams.filter,
    //   ordering: searchParams.ordering
    // })
  });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeStatistics", page_size.toString());
    // const lastPageNumber = getLastPageNumber(statisticsInfoList?.data?.count || 0, page_size);
    const lastPageNumber = getLastPageNumber(0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onFilterClick = (filter: TStatisticsFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeContact");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const client_role = newSearchParams.client_role
      ? getNumberArrayFromSearchParam(newSearchParams.client_role)
      : defaultSearchParams.filter?.client_role;
    const contractor_role = newSearchParams.contractor_role
      ? getNumberArrayFromSearchParam(newSearchParams.contractor_role)
      : defaultSearchParams.filter?.contractor_role;
    const client = newSearchParams.client
      ? +newSearchParams.client
      : defaultSearchParams.filter?.client;
    const contractor = newSearchParams.contractor
      ? +newSearchParams.contractor
      : defaultSearchParams.filter?.contractor;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        client_role,
        contractor_role,
        client,
        contractor
      }
    });
    dispatch(setStatisticsLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  if (statisticsError) {
    return <div>Error</div>;
  }

  const statistics: Invoice[] = [];

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onOrderSelect={onOrderSelect}
      onPageSizeSelect={onPageSizeSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/statistics/add"
      isDataLoading={statisticsLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentOrder={searchParams.ordering}
      currentFilter={searchParams.filter}
      orderVariants={orderVariants}
      count={0}
      table={<StatisticsTable invoices={statistics} />}
      filter={
        <StatisticsFilter
          filter={searchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
      searchPlaceholder="Введите ID элемента, название ООО, ИНН или номер договора"
    />
  );
};
