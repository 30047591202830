import { ID } from "models";
import { ReactNode } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardLink,
  Input,
  ListGroup
} from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import { TListingItem } from "types/TListingItem";
import { ActionSelectorProps } from "ui/ActionSelector";

interface Props extends IExportRelatedProps {
  id?: ID;
  header?: ReactNode;
  list: ReactNode;
  actions: (ActionSelectorProps | null)[];
  item?: TListingItem;
  onHeaderClick?: (_: ID) => void;
  href?: string;
}

const ListingCard = ({
  id,
  header,
  list,
  actions,
  href,
  onHeaderClick,
  onExportIdCheck,
  exportIds,
  item
}: Props) => (
  <Card className="my-2">
    <CardHeader className="d-flex justify-content-between">
      <CardLink href={href} onClick={() => id && onHeaderClick && onHeaderClick(id)}>
        {header || id}
      </CardLink>
      {id && onExportIdCheck && exportIds ? (
        <Input
          type="checkbox"
          onChange={() => onExportIdCheck(+id)}
          checked={exportIds.includes(+id)}
        />
      ) : null}
    </CardHeader>
    <ListGroup flush>{list}</ListGroup>
    <CardFooter className="border-top justify-content-center">
      <ButtonGroup size="sm">
        {actions.map((action) => {
          if (!action) return null;

          const { title, onClick, calcHref, calcIsDisabled } = action;
          const isDisabled = calcIsDisabled && item && calcIsDisabled(item);

          if (calcHref) {
            const linkProps = calcHref(id);
            return linkProps ? (
              <a
                href={linkProps?.href}
                className="btn"
                key={title}
                target="_blank"
                rel="noreferrer"
                download={linkProps?.download}
              >
                {title}
              </a>
            ) : (
              <Button disabled>{title}</Button>
            );
          }

          return (
            <Button
              key={title}
              disabled={isDisabled}
              onClick={() => (id && onClick ? onClick(+id, item) : null)}
            >
              {title}
            </Button>
          );
        })}
      </ButtonGroup>
    </CardFooter>
  </Card>
);

export default ListingCard;
