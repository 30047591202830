import {CreativeV2, MediaDataV2, TextDataV2} from "models";
import { TEditCreativeFunctionProps } from "../types";
import {IAttachmentOld, TAttachment} from "types/AttachmentsTypes";
import {useMemo} from "react";

const editCreativeFunction = ({
  id,
  isDraft,
  watch,
  creative,
  groupInfo,
  edit
}: TEditCreativeFunctionProps) => {

  const group_id = watch("group_id");
  // const advertiser_urls = watch("advertiser_urls");
    const attachments = watch("attachments")

  const isOldAttachment = (attachment: IAttachmentOld | TAttachment): attachment is IAttachmentOld  => {
      return (attachment as IAttachmentOld).id !== undefined
  }

    const isGroupNotEdited =
    creative?.data.extra_fields?.group?.final_contract === groupInfo?.final_contract ||
    creative?.data.extra_fields?.group?.initial_contract === groupInfo?.initial_contract;

  const groupParams = { group: group_id };
  // TODO больше нельзя создавать новые группы греативов вместе с редактированием.
  // const groupParams = isGroupNotEdited
  //   ? { group_id }
  //   : { group: groupInfo ? omit(groupInfo, ["id"]) : undefined };

  const formattedTexts = (attachments || []).filter(attachment => attachment.type === 'text').map(attachment => isOldAttachment(attachment)
      ? ({
          id: attachment.id,
          text: attachment.text,
      })
      : ({text: attachment.text}))

  const formattedMedias = (attachments || []).filter(attachment => attachment.type === 'file').map(attachment => isOldAttachment(attachment)
      ? ({
          id: attachment.id,
          file_path: attachment.file_path,
          description: attachment.description
      })
      : ({file_path: attachment.filePath,
            description: attachment.description
      }))

  const result: CreativeV2 = {
    status: isDraft ? "2" : "1",
    // Нужно ли это преобразование? Все равно не сохраняются данные...
    // advertiser_urls: advertiser_urls?.map(({ advertiser_url }) => advertiser_url),
    ...groupParams,
    texts: formattedTexts as TextDataV2[],
    medias: formattedMedias as MediaDataV2[],
  };
  edit({ id, creative: result });
};

export default editCreativeFunction;
