import axios from "axios";
import Cookies from "js-cookie";
import { CreativeV2, INotification } from "models";

type TAddCreativeV2Return = {
  status: boolean;
  data?: CreativeV2;
  notifications?: INotification[];
};

export const addCreativeV2 = (creative: CreativeV2) => {
  return axios
    .post(`/api/v2/creative/`, creative, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json"
      }
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data as TAddCreativeV2Return;
      }
      return { status: false } as TAddCreativeV2Return;
    })
    .catch((err) => {
      console.log(err);
      return { status: false } as TAddCreativeV2Return;
    });
};
