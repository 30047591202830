import creative_type from "dictionaries/creative_type.json";
import { BaseSyntheticEvent } from "react";
import { Control, FieldErrors, FieldValues, UseFormWatch } from "react-hook-form/dist/types";
import { Button, Form } from "reactstrap";
import { DatePickerField, InputField, SelectField, TextAreaField } from "ui";
import createOptions from "utils/createOptions";
import { IDefaultValuesCreativeGroupEditor } from "../types";
import {KktuSelect} from "../../CreativeAddForm";
import {creativesFormsObject} from "constants/CreativesConstants";

type Props = {
  control: Control<IDefaultValuesCreativeGroupEditor, any>;
  errors: FieldErrors<IDefaultValuesCreativeGroupEditor>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  watch: UseFormWatch<IDefaultValuesCreativeGroupEditor>;
  creativeLoading?: boolean;
  backToListing: () => void;
};

const CreativeGroupForm = ({
  control,
  errors,
  onSubmit,
  watch,
  creativeLoading,
  backToListing
}: Props) => {
  const creativeAdvertisementFormsOptions = createOptions(creativesFormsObject);
  const creativeAdvertisementsTypesOptions = createOptions(creative_type);

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className="row">
        <div className="col-sm-9">
          <InputField
            name="label"
            title="Название группы креативов"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            isRequired
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <SelectField
            name="advertisement_type"
            title="Тип рекламной кампании"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            options={creativeAdvertisementsTypesOptions}
            disabled={creativeLoading}
            isRequired
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <SelectField
            name="advertisement_form"
            title="Форма распространения"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            options={creativeAdvertisementFormsOptions}
            disabled
            isStared
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <DatePickerField
            name="start_date"
            title="Дата начала периода размещения"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            disabled={creativeLoading}
            returnFormat="yyyy-MM-dd"
            isRequired={!!watch("start_date") || !!watch("end_date")}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <DatePickerField
            name="end_date"
            title="Дата окончания периода размещения"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            disabled={creativeLoading}
            returnFormat="yyyy-MM-dd"
            isRequired={!!watch("start_date") || !!watch("end_date")}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <SelectField
            name="peculiarities"
            title="Особенности рекламной кампании"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            options={[
              { value: "is_social", label: "Социальная" },
              { value: "is_native", label: "Нативная" }
            ]}
            disabled={creativeLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9 mb-3">
          <KktuSelect control={control as unknown as Control<FieldValues>} errors={errors} isDisabled={!!creativeLoading} isRequired />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <InputField
            name="target_audience"
            title="Параметры целевой аудитории"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            disabled={creativeLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <TextAreaField
            name="description"
            title="Общее описание объекта рекламирования"
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            disabled={creativeLoading}
            infoText={
              <>
                <p>Укажите:</p>
                <ul>
                  <li>Бренд (или несколько брендов) рекламируемых товаров или услуг;</li>
                  <li>Вид товара / услуги;</li>
                  <li>Дополнительную информацию.</li>
                </ul>
                <p>Примеры:</p>
                <ul>
                  <li>Tide. Стиральный порошок. Годовой запас в подарок.</li>
                  <li>
                    Отель «Ромашка». Бронирование номеров.Гостиница, отель в Подмосковье на
                    выходные.
                  </li>
                </ul>
              </>
            }
            isRequired
          />
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-sm-9 mb-3">
          <p className="mb-0 text-primary">
            Внесенные Вами изменения будут применены ко&nbsp;всем креативам в&nbsp;данной Группе
            креативов.
          </p>
          <p className="mb-0 text-primary">
            После сохранения изменений, креативы со&nbsp;статусами &quot;Активный&quot;,
            &quot;Ошибка регистрации&quot; или &quot;Зарегистрировано в&nbsp;ЕРИР&quot; будут
            автоматически отправлены на&nbsp;регистрацию в&nbsp;ЕРИР.
          </p>
          <p className="mb-0 text-primary">
            Для отправки креативов в&nbsp;статусе &quot;Черновик&quot; необходимо выполнить это
            действие вручную из&nbsp;карточки креатива.
          </p>
        </div>
        <div className="col-sm-3 d-flex flex-wrap gap-3 mb-3 justify-content-end">
          <Button type="button" color="primary" outline onClick={backToListing}>
            Отменить
          </Button>
          <Button type="submit" color="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default CreativeGroupForm;
