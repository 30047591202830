import ChangeContractModal from "Components/ChangeContractModal";
import { ErrorMessage } from "Components/ErrorMessage";
import {ID} from "models";
import {ReactNode, useState} from "react";
import { useForm } from "react-hook-form";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StateProps } from "reducers";
import IconButton from "ui/IconButton";
import useIsOpenControl from "utils/useIsOpenControl";
import ContractNumber, { TContractInfo } from "./components/contractNumber";
import CreativesModal from "./components/creativesModal";
import CreativeGroupForm from "./components/form";
import { useEditCreativeGroup } from "./hooks/useEditCreativeGroup";
import { useLoadCreativeGroup } from "./hooks/useLoadCreativeGroup";
import { IDefaultValuesCreativeGroupEditor } from "./types";

const CreativeGroupEditForm = ({ id }: { id: ID }) => {
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  // const [isDraft, setIsDraft] = useState(false);
  const navigate = useNavigate();
  const [contractInfo, setContractInfo] = useState<TContractInfo | undefined>();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError,
    setValue
  } = useForm<IDefaultValuesCreativeGroupEditor>({
    defaultValues: {} as IDefaultValuesCreativeGroupEditor
  });

  const { isOpen: isChangeContractModalOpen, toggle: toggleChangeContractModal } =
    useIsOpenControl();
  const { isOpen: isCreativesModalOpen, toggle: toggleCreativesModal } = useIsOpenControl();

  const backToListing = () => navigate(`/creative/${creativeLastSearch}`);

  const edit = useEditCreativeGroup({ backToListing, setError });

  const { isLoading, error, creativeGroup, isContractEditable } = useLoadCreativeGroup({ id, reset, setContractInfo });
  const editCreativeGroupFunction = () => {
    const is_native =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_native";
    const is_social =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_social";

    const result = {
      ...watch(),
      is_native,
      is_social
    };

    edit({ id, group: result });
  };

  const onSubmit = handleSubmit(editCreativeGroupFunction, (err) => {
    console.log(err);
  });

  if (error) return <ErrorMessage error={error.message} isEmbedded />;

  return (
    <div>
      <div className="row align-items-center mb-5">
        <h1 className="col-sm-9">
          Редактирование группы креативов {creativeGroup?.data.label || creativeGroup?.data.id}
        </h1>
        {creativeGroup ? (
          <div className="col-sm-3">
            <p className="form-label">
              Креативов в группе:{" "}
              {creativeGroup.data.extra_fields?.creatives
                ? creativeGroup.data.extra_fields?.creatives.length
                : ""}
            </p>
            <IconButton
              outline
              isFullWidth
              text="Посмотреть"
              icon={<FaMagnifyingGlass size={14} /> as ReactNode}
              onClick={toggleCreativesModal}
            />
          </div>
        ) : null}
      </div>

      <ContractNumber
        contract={contractInfo}
        toggle={toggleChangeContractModal}
        isEditable={isContractEditable}
      />

      <CreativeGroupForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        creativeLoading={isLoading}
        backToListing={backToListing}
      />

      <CreativesModal
        isOpen={isCreativesModalOpen}
        toggle={toggleCreativesModal}
        creatives={creativeGroup?.data.extra_fields?.creatives}
        label={creativeGroup?.data.label || creativeGroup?.data.id}
      />

      <ChangeContractModal
        isOpen={isChangeContractModalOpen}
        toggle={toggleChangeContractModal}
        groupId={id}
        onSubmit={(
          { initial_contract, final_contract },
          newInitialContractInfo,
          newFinalContractInfo
        ) => {
          setValue("initial_contract", initial_contract);
          setValue("final_contract", final_contract);
          if (newInitialContractInfo && newInitialContractInfo.id) {
            setContractInfo({
              id: newInitialContractInfo.id,
              number: newInitialContractInfo.contract_number,
              clientId: newInitialContractInfo.client,
              clientName: newInitialContractInfo.extra_fields?.client.name,
              contractorId: newInitialContractInfo.contractor,
              contractorName: newInitialContractInfo.extra_fields?.contractor.name
            });
          }
          setValue(
            "final_contract_client_legal_form",
            newFinalContractInfo?.extra_fields?.client.legal_form
          );
          toggleChangeContractModal();
        }}
      />
    </div>
  );
};

export default CreativeGroupEditForm;
