import get from "lodash/get";
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FaArrowUp } from "react-icons/fa";
import { TAttachment } from "types/AttachmentsTypes";
import AttachedFilesTable from "ui/AttachedFilesTable";
import getByteToGigabyte from "utils/getByteToGigabyte";
import { TAttachmentsTableElemntProps } from "../../types";

const CreativeFormAttachmentsTable = ({
  isLoading,
  isError,
  selectedFields,
  attachmentListOnDelete,
  setValue,
  creativeId,
  watch,
  isDraft,
  onFormChange,
  clearErrors,
  errors,
  control
}: TAttachmentsTableElemntProps) => {
  const isTableShown =
    selectedFields &&
    (selectedFields.includes("attachments") || selectedFields.includes("text_data"));

  if (isLoading) return <p>Загрузка...</p>;

  if (isError) return <p>Произошла ошибка загрузки</p>;

  if (!isTableShown) return <></>;

  const formError = get(errors, "attachments");

  return (
    <div className="row">
      <div className="col-sm-12">
        <AttachedFilesTable
          attachedFiles={watch("attachments") as TAttachment[]}
          onDelete={(toDeleteId) => attachmentListOnDelete(toDeleteId)}
          creativeId={creativeId}
          setValue={setValue as unknown as UseFormSetValue<FieldValues>}
          nameAndKey={{ name: "attachments", key: "description" }}
          watch={watch as unknown as UseFormWatch<FieldValues>}
          isNotDraftCreative={!isDraft}
          control={control}
          errors={errors}
          extraAction={() => {
            if (onFormChange) onFormChange();
            const files = watch("attachments");
            if (files) {
              const sumWeight = getByteToGigabyte(
                files.reduce((prev, f) => prev + (f.file?.size || f.file_size || 0), 0)
              );
              if (sumWeight <= 1) {
                clearErrors("attachments");
              }
            }
          }}
        />
        {!!formError && !!formError?.message && formError?.type !== "required" ? (
          <p className="text-danger text-center mt-2">{formError.message}</p>
        ) : null}

        {!!formError && formError?.type === "required" ? (
          <p className="text-danger text-center mt-2">
            <FaArrowUp /> Необходимо добавить медиаданные <FaArrowUp />
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default CreativeFormAttachmentsTable;
