import axios from "axios";
import { CreativeV2, ID } from "models";
import { APIData } from "./types";

export const getCreativeV2Item = (id?: ID): Promise<APIData<CreativeV2>> => {
  return axios
    .get(`/api/v2/creative/${id}/`, {
      headers: {
        // "X-CSRFToken": Cookies.get("csrftoken"),
        // "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
    .then((res) => {
      return res.data as Promise<APIData<CreativeV2>>;
    });
};
