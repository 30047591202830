import {
  IMAGE_EXTENSIONS,
  SOUND_EXTENSIONS,
  VIDEO_EXTENSIONS,
  ZIP_EXTENSIONS,
} from "constants/FileExtensions";
import { ID } from "models";
import {Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FaFile, FaFileAudio, FaFileImage, FaFileVideo } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { TFilesStates } from "types/AttachmentsTypes";
import IconButton from "ui/IconButton";
import getFileExtensionFromPath from "utils/getFileExtensionFromPath";
import getTextShortened from "utils/getTextShortened";
import { TDisplayObj } from "../AttachmentPreviewModal";
import DescriptionCell from "./DescriptionCell";
import RemoveCell from "./RemoveCell";
import {Control, FieldErrors} from "react-hook-form/dist/types";
import {InputField} from "../../Input";

type Props = {
  tempId: string;
  id?: ID;
  data?: string;
  description?: string;
  file?: File;
  fileState?: TFilesStates;
  fileSize: number | undefined;
  watch?: UseFormWatch<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  index: number;
  setDisplayObj: Dispatch<SetStateAction<TDisplayObj | null>>;
  toggle: () => void;
  onDelete: (_id: ID) => void;
  creativeId?: ID;
  descriptionFromOutside?: string;
  nameAndKey?: {
    name: string;
    key: string;
  };
  isNotDraftCreative?: boolean;
  extraAction?: () => void;
  isAnythingLoading?: boolean;
  isAlreadyAttachedFile?: boolean;
  isRecentlyAttachedFile?: boolean;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

const FileRow = ({
  tempId,
  id,
  data,
  description,
  file,
  fileState,
  fileSize,
  watch,
  setValue,
  index,
  setDisplayObj,
  toggle,
  onDelete,
  creativeId,
  descriptionFromOutside,
  nameAndKey,
  isNotDraftCreative,
  extraAction,
  isAnythingLoading,
  isAlreadyAttachedFile,
  isRecentlyAttachedFile,
  control,
  errors,
}: Props) => {

  let displayObj: TDisplayObj = {};
  if (isRecentlyAttachedFile) {
    displayObj.url = URL.createObjectURL(file!);
    displayObj.name = file!.name;
    displayObj.size = file!.size;
    displayObj.extension = getFileExtensionFromPath(file!.name);
  }

  if (isAlreadyAttachedFile) {
    displayObj.url = `/api/v1/creative/${creativeId}/media/${id}/`;
    displayObj.name = data;
    displayObj.size = fileSize;
    displayObj.extension = getFileExtensionFromPath(data!);
  }

  const fileExtension = displayObj?.extension;

  const isImage = fileExtension && IMAGE_EXTENSIONS.includes(fileExtension)
  const isVideo = fileExtension && VIDEO_EXTENSIONS.includes(fileExtension)
  const isAudio = fileExtension && SOUND_EXTENSIONS.includes(fileExtension)
  const isZip = fileExtension && ZIP_EXTENSIONS.includes(fileExtension)

  return (
    <tr key={tempId} className={isAlreadyAttachedFile ? "table-secondary" : ""}>
      <td style={{ verticalAlign: "middle" }}>
        <IconButton
          icon={
            <>
              {isImage ? (
                <FaFileImage size={15} />
              ) : null}
              {isVideo ? (
                <FaFileVideo size={15} />
              ) : null}
              {isAudio ? (
                <FaFileAudio size={15} />
              ) : null}
              {isZip ? (
                <FaFile size={15} />
              ) : null}
            </> as ReactNode
          }
          text={getTextShortened(file?.name || data, 100) || ""}
          onClick={() => {
            toggle();
            setDisplayObj(displayObj);
          }}
          outline
          className="border-0"
          dir="itt"
        />
      </td>

      <td style={{ verticalAlign: "middle" }}>
        {
          isImage ? (
          <div className="row">
          <InputField
                name={`${nameAndKey?.name}.${index}.${nameAndKey?.key}`}
                title={''}
                control={control}
                errors={errors}
                maxLength={1000}
                isClearable
                noMargin
                textEllipsis
              />
          </div>)
            : <DescriptionCell
              descriptionFromOutside={descriptionFromOutside}
              isNotDraftCreative={isNotDraftCreative}
              description={description}
              name={`${nameAndKey?.name}.${index}.${nameAndKey?.key}`}
              watch={watch}
              setValue={setValue}
              extraAction={extraAction}
          />
        }

      </td>

      <td style={{ verticalAlign: "middle" }}>
        <div className="d-flex justify-content-center">
          <a
            href={displayObj.url!}
            download
            className="p-0 border-0 d-inline-flex justify-content-center align-items-center btn btn-outline-secondary"
            style={{ width: "25px", height: "25px" }}
          >
            <MdOutlineFileDownload size={24} />
          </a>
        </div>
      </td>

      <td style={{ verticalAlign: "middle" }}>
        <RemoveCell
          tempId={tempId}
          fileState={fileState}
          onDelete={onDelete}
          extraAction={extraAction}
          isAnythingLoading={isAnythingLoading}
          isNotDraftCreative={isNotDraftCreative}
        />
      </td>
    </tr>
  );
};

export default FileRow;
