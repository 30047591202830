import axios from "axios";
import Cookies from "js-cookie";
import {CreativeGroupV2} from "models";

export const addCreativeGroupV2 = (creativeGroup: CreativeGroupV2) => {
  return axios
    .post(`/api/v2/creative_group/`, creativeGroup, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json"
      }
    })
    .then((res) => {
      return res;
    });
};
