import { CreativeV2, ID } from "models";
import { Dispatch, SetStateAction } from "react";
import { UseMutateFunction } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { TAPIResponseError } from "types/ResponseTypes";
import { TListingItem } from "types/TListingItem";
import { ActionSelectorProps } from "ui/ActionSelector";

const getActions = (
  creatives: CreativeV2[],
  delCreative: UseMutateFunction<"success" | TAPIResponseError, unknown, string | number, unknown>,
  navigate: NavigateFunction,
  setCurrentGroupInfo: Dispatch<
    SetStateAction<{
      creativeId?: ID;
      groupId?: ID;
      isDraft?: boolean;
    }>
  >,
  toggleChangeGroupModal: () => void
): ActionSelectorProps[] => [
  {
    title: "Редактировать креатив",
    calcHref: (id) => ({ href: `#/creative/${id}/` })
  },
  {
    title: "Редактировать группу",
    onClick: (id: ID) => {
      const currentCreative = creatives.find((cr) => cr.id === id);
      const group = currentCreative?.group;
      const groupId = typeof group === "string" || typeof group === "number" ? group : undefined;
      if (currentCreative && groupId) navigate(`/creative/group/${groupId}/`);
    }
  },
  {
    title: "Сменить группу",
    onClick: (id: ID, item?: TListingItem | undefined) => {
      const currentCreative = item as CreativeV2 | undefined;
      const group = currentCreative?.group;
      const groupId = typeof group === "string" || typeof group === "number" ? group : undefined;
      setCurrentGroupInfo({
        creativeId: id,
        groupId,
        isDraft: currentCreative?.status && +currentCreative.status === 2
      });
      toggleChangeGroupModal();
    }
  },
  {
    title: "Удалить креатив",
    onClick: (id) => (id ? delCreative(id) : null),
    calcIsDisabled: (c) => {
      const creative = c as CreativeV2;
      return !!creative?.status && +creative.status === 1;
    }
  }
];

export default getActions;
