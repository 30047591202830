import uniqBy from "lodash/uniqBy";
import { useMutation } from "react-query";
import {getCreativeGroupV2Info} from "api/creative_group_v2";

const useSearchGroupMutation = (searchGroupValue?: string, ids?: number[]) =>
  useMutation({
    mutationFn: () =>
      Promise.all([
        getCreativeGroupV2Info({
          search: searchGroupValue,
          filter: {
            id__not_in: ids
          }
        }),
        getCreativeGroupV2Info({ filter: { id: ids } })
      ])
        .then(([searchRes, byIdsRes]) => {
          const results = uniqBy([...searchRes.data.results, ...byIdsRes.data.results], "id");
          return {
            status: true,
            data: { count: results.length, next: "", previous: "", results }
          };
        })
        .catch(() => ({ status: false, data: { count: 0, next: "", previous: "", results: [] } }))
  });

export default useSearchGroupMutation;
