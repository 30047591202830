import { DISPLAY_FIELDS, FILE_EXTENSIONS } from "constants/CreativesConstants";
import { ID } from "models";
import { Control, FieldValues, UseFormWatch } from "react-hook-form";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Button, Card, Collapse, Form } from "reactstrap";
import { TAttachment } from "types/AttachmentsTypes";
import { CreativeFormKeys } from "types/keys";
import { CheckBoxField, InputMultiple } from "ui";
import useIsOpenControl from "utils/useIsOpenControl";
import { TCreativeFormElementProps } from "../../types";
import CreativeFormAttachmentsTable from "./CreativeFormAttachmentsTable";
import CreativeFormCardBody from "./CreativeFormCardBody";

const CreativeForm = ({
  control,
  errors,
  onSubmit,
  onFormChange,
  watch,
  creativeLoading,
  setValue,
  clearErrors,
  attributes,
  creativeId,
  buttons,
  isDraft
}: TCreativeFormElementProps) => {
  const { isOpen: isEditOpen, toggle: toggleEdit } = useIsOpenControl();
  const isLoading = attributes === "loading";
  const isError = attributes === "error";
  const advertisement_form = !isLoading && !isError ? attributes.advertisement_form : undefined;

  const selectedFileExtensions = advertisement_form
    ? FILE_EXTENSIONS[advertisement_form.toString() as CreativeFormKeys]
    : undefined;
  const selectedFields = advertisement_form
    ? DISPLAY_FIELDS[advertisement_form.toString() as CreativeFormKeys]
    : undefined;

  const attachmentListOnDelete = (toDeleteId: ID) => {
    const previousValue = watch(`attachments`) as TAttachment[];
    setValue(
      `attachments`,
      previousValue.filter((f) => f.tempId !== toDeleteId)
    );
    return;
  };

  return (
    <>
      <Form
        onSubmit={(event) => {
          void onSubmit(event);
        }}
        // onBlur={onFormChange}
        className="mt-3 mb-3"
      >
        <div className="row">
          <div className="col-sm-6">
            <InputMultiple
              name="advertiser_urls"
              title="Целевая ссылка"
              isRequired={!watch("no_urls")}
              control={control as unknown as Control<FieldValues, any>}
              errors={errors}
              disabled={!!watch("no_urls") || creativeLoading}
              watch={watch as unknown as UseFormWatch<FieldValues>}
              prefix={["https://", "http://"]}
              keyInObject="advertiser_url"
              maxLength={2000}
              extraAction={onFormChange}
            />
          </div>

          <div className="col-sm-6">
            <p>
              Целевая ссылка – посадочная страница, которая ведет пользователя с креатива на ресурс
              Рекламодателя на страницу рекламируемого товара. Достаточно указать url конкретной
              страницы, но если utm метка влияет на вид страницы, то ссылку указываем с utm или
              добавляем несколько ссылок.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <CheckBoxField
              name="no_urls"
              title="Нет целевых ссылок"
              control={control as unknown as Control<FieldValues, any>}
              errors={errors}
              isChecked={!!watch("no_urls")}
              disabled={creativeLoading}
              extraAction={onFormChange}
            />
          </div>
        </div>

        <Button
          color="secondary"
          onClick={toggleEdit}
          className="w-100 d-flex justify-content-between align-items-center"
          style={{
            marginBlock: "1rem"
          }}
        >
          <span>Добавить медиаданные</span>
          {isEditOpen ? <FaChevronUp /> : <FaChevronDown />}
        </Button>
        <Collapse isOpen={isEditOpen}>
          <Card className="shadow-none p-3">
            <CreativeFormCardBody
              isLoading={isLoading}
              isError={isError}
              selectedFields={selectedFields}
              selectedFileExtensions={selectedFileExtensions}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              watch={watch}
              creativeLoading={creativeLoading}
              onFormChange={onFormChange}
              setValue={setValue}
            />
          </Card>
        </Collapse>

        <CreativeFormAttachmentsTable
          isLoading={isLoading}
          isError={isError}
          selectedFields={selectedFields}
          attachmentListOnDelete={attachmentListOnDelete}
          setValue={setValue}
          creativeId={creativeId}
          watch={watch}
          isDraft={isDraft}
          onFormChange={onFormChange}
          control={control as unknown as Control<FieldValues>}
          errors={errors}
          clearErrors={clearErrors}
        />

        {buttons}
      </Form>
    </>
  );
};

export default CreativeForm;
