import { APIListData } from "api/types";
import flatten from "lodash/flatten";
import get from "lodash/get";
import isArray from "lodash/isArray";
import unionBy from "lodash/unionBy";
import { Dispatch, SetStateAction, useDeferredValue, useLayoutEffect, useMemo } from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
  UseFormStateReturn,
  Validate
} from "react-hook-form";
import Select, { InputActionMeta, MultiValue, SingleValue } from "react-select";
import { FormGroup } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";
import createOptions from "utils/createOptions";
import DropdownIndicator from "./components/DropdownIndicator";
import { MenuComponent } from "./components/MenuComponent";
import MultiFieldMultiValueLabel from "./components/MultiFieldMultiValueLabel";
import MultiFieldOption from "./components/MultiFieldOption";
import MultiFieldSingleValue from "./components/MultiFieldSingleValue";

type Props = {
  control: Control<any, any>;
  errors?: FieldErrors<FieldValues>;
  name: string;
  title: string;
  placeholder?: string;
  errorText?: string;
  defaultOptions?: { value: number; label: string; isDisabled: boolean | undefined }[];
  optionKey: string | string[]; // Нужен для указания ключа, по которому будет искаться значение для label
  optionKeyLabels?: string[];
  disabled?: boolean;
  isLoading?: boolean;
  inputValue: string | undefined;
  isRequired?: boolean;
  isStared?: boolean;
  isReturnLabelAsValue?: boolean; // Отвечает, за возврат значения опираясь на label, а не value
  request: (_?: any) => void;
  response?: APIListData<any>;
  setInputValue: Dispatch<SetStateAction<string | undefined>>;
  isMulti?: boolean;
  clearable?: boolean;
  optionDisableCondition?: { [n: string]: string | number } | { [n: string]: string | number }[];
  onChange?: (_: any) => void;
  onMenuOpen?: () => void;
  initialValues?: Record<string, string>[];
  infoText?: string;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>> | undefined;
  isLoadMoreEnabled?: boolean;
  optionsLength?: number;
};

type renderProps = {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

export type TNewVal = {
  value: number | string;
  label: string;
  isDisabled?: boolean;
};

export const PredictiveSearchField = ({
  name,
  title,
  placeholder,
  control,
  errors,
  isRequired,
  isStared,
  errorText,
  defaultOptions,
  disabled,
  inputValue,
  setInputValue,
  isLoading,
  isReturnLabelAsValue,
  request,
  response,
  optionKey,
  optionKeyLabels,
  isMulti,
  clearable,
  optionDisableCondition,
  onChange: onValueChange,
  onMenuOpen,
  initialValues,
  infoText,
  validate,
  isLoadMoreEnabled,
  optionsLength = 10
}: Props) => {
  const defferedInput = useDeferredValue(inputValue) || undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(request, [defferedInput]);

  /** Преобразование полученных юнитов из данных поиска в options */
  const options = useMemo(() => {
    const result = unionBy(
      response?.data?.results as Record<string, string>[],
      initialValues,
      "id"
    );
    const mutatedData: { [key: string]: string } =
      result?.reduce((acc, curr) => {
        return {
          ...acc,
          [`_${curr.id}`]:
            typeof optionKey === "string"
              ? curr?.[`${optionKey}`]
              : JSON.stringify(optionKey.reduce((a, c) => ({ ...a, [c]: get(curr, c) }), {}))
        };
      }, {}) || {};

    return createOptions(mutatedData as Record<number, string>, optionDisableCondition) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, optionKey]);

  const inputValueChangeHandler = (text: string, action: InputActionMeta) => {
    if (action?.action !== "input-blur" && action?.action !== "menu-close") {
      setInputValue(text);
    }
  };

  const renderField = ({ field: { value, onChange, onBlur } }: renderProps) => {
    const onChangeHandler = (newVal: MultiValue<TNewVal> | SingleValue<TNewVal>) => {
      if (newVal !== null) {
        const newOnChangeValue = isMulti
          ? isReturnLabelAsValue
            ? (newVal as MultiValue<TNewVal>).map(({ label }) => label)
            : (newVal as MultiValue<TNewVal>).map(({ value }) => value)
          : isReturnLabelAsValue
            ? (newVal as SingleValue<TNewVal>)?.label
            : (newVal as SingleValue<TNewVal>)?.value;
        onChange(newOnChangeValue);
        if (onValueChange) onValueChange(newOnChangeValue);
        if (isMulti) {
          setTimeout(() => {
            request();
          }, 100);
        }
      } else {
        onChange(null);
        if (onValueChange) onValueChange(null);
        request();
      }
    };

    let currentValue: TNewVal | TNewVal[] | null = null;
    if (value) {
      if (isArray(value)) {
        currentValue = value.map((v) =>
          options?.find((item) => item.value === v || item.label === v)
        ) as TNewVal[];
      } else {
        currentValue = options?.find(
          (item) => item.value === value || item.label === value
        ) as TNewVal;
      }
    }

    if (currentValue && !isArray(currentValue) && typeof optionKey !== "string") {
        const obj = JSON.parse(currentValue.label) as { [key: string]: string };
        const strings = optionKey.map((key) => obj[key]);
        if (inputValue && strings.includes(inputValue)) setInputValue("");
    }

    return (
      <FormGroup>
        <FieldLabel
          title={title}
          isRequired={isRequired || isStared}
          info={
            infoText
              ? {
                  text: infoText,
                  id: `info_${name}`
                }
              : undefined
          }
        />
        <Select
          placeholder={placeholder || title}
          id={name}
          onFocus={() => !isLoadMoreEnabled && request()}
          inputValue={inputValue}
          value={currentValue}
          options={[...(defaultOptions || []), ...options]}
          onInputChange={inputValueChangeHandler}
          filterOption={() => true}
          onChange={onChangeHandler}
          onMenuOpen={onMenuOpen}
          loadingMessage={() => "Загрузка..."}
          noOptionsMessage={() => "Ничего не найдено"}
          components={
            typeof optionKey === "string"
              ? {
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Menu: (props) => MenuComponent(props, isLoadMoreEnabled, request, optionsLength)
                }
              : {
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Option: (props) => MultiFieldOption(props, optionKey, optionKeyLabels),
                  MultiValueLabel: (props) =>
                    MultiFieldMultiValueLabel(props, optionKey, optionKeyLabels),
                  SingleValue: (props) => MultiFieldSingleValue(props, optionKey, optionKeyLabels),
                  Menu: (props) => MenuComponent(props, isLoadMoreEnabled, request, optionsLength)
                }
          }
          isDisabled={disabled}
          isLoading={isLoading}
          isMulti={isMulti}
          styles={{
            control: (styles): any => {
              const defaultValue: any =
                typeof optionKeyLabels !== "string" &&
                optionKeyLabels &&
                optionKeyLabels?.filter((item) => item !== "hidden").length > 1
                  ? {
                      ...styles,
                      minHeight: `calc(${
                        1.5 * optionKeyLabels?.filter((item) => item !== "hidden").length
                      }em + 1rem)`
                    }
                  : styles;
              return !!errors && !!get(errors, name)
                ? {
                    ...defaultValue,
                    borderColor: "#d92550"
                  }
                : defaultValue;
            },
            menu: (styles): any => ({ ...styles, zIndex: 99 })
          }}
          isClearable={clearable}
          onBlur={onBlur}
        />

        <FieldFeedback name={name} errors={errors} errorText={errorText} />
      </FormGroup>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate
      }}
      render={renderField}
    />
  );
};
