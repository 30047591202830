import { patchCreativeGroupV2 } from "api/creative_group_v2_patch";
import { AxiosError } from "axios";
import omit from "lodash/omit";
import { UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { TAPIResponseError } from "types/ResponseTypes";
import createNotification from "ui/Notification";
import onErrorMutation from "utils/onErrorMutation";
import { IDefaultValuesCreativeGroupEditor } from "../types";

export const useEditCreativeGroup = ({
  backToListing,
  setError
}: {
  backToListing: () => void;
  setError: UseFormSetError<IDefaultValuesCreativeGroupEditor>;
}) => {
  const { mutate: edit } = useMutation({
    mutationFn: patchCreativeGroupV2,
    onSuccess: () => {
      createNotification(
        "success",
        <>
          <p className="fw-bold">Группа креативов успешно изменена</p>
          <p>
            Креативы со&nbsp;статусами &quot;Активный&quot;, &quot;Ошибка регистрации&quot; или
            &quot;Зарегистрировано в&nbsp;ЕРИР&quot; отправляются автоматически на&nbsp;регистрацию
            в&nbsp;ЕРИР.
          </p>
          <p>
            Для отправки креативов в&nbsp;статусе &quot;Черновик&quot; необходимо выполнить это
            действие вручную из&nbsp;карточки креатива.
          </p>
        </>
      );
      backToListing();
    },
    onError: (err: AxiosError) => {
      if (err.response?.data) {
        const errorData = err.response.data as TAPIResponseError;
        const formErrorsKeys = Object.keys(errorData.form_errors);
        if (formErrorsKeys.length) {
          return onErrorMutation(
            {
              ...err,
              response: {
                ...err.response,
                data: {
                  ...errorData,
                  form_errors:
                    formErrorsKeys.includes("is_native") || formErrorsKeys.includes("is_social")
                      ? omit(
                          {
                            ...errorData.form_errors,
                            peculiarities: [
                              "Поле 'Особенности рекламной кампании' не может быть пустым"
                            ]
                          },
                          ["is_native", "is_social"]
                        )
                      : errorData.form_errors
                }
              }
            } as AxiosError,
            setError
          );
        }
      }
    }
  });

  return edit;
};
