import ErrorComponent from "Components/ErrorComponent";
import EditorFooter from "Layout/EditorFooter";
import { format } from "date-fns";
import contract_status from "dictionaries/contract_status.json";
import omit from "lodash/omit";
import { Contract, ID } from "models";
import { JSX, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { AttributesDropdown } from "ui/AttributesDropdown";
import StatusInline from "ui/StatusDisplay/StatusInline";
import { scrollToError } from "utils/scrollToError";
import { ContractAttributesBody } from "./components/attributes";
import { ContractForm } from "./components/form";
import useDeleteContract from "./hooks/useDeleteContract";
import useEditContract from "./hooks/useEditContract";
import useLoadContract from "./hooks/useLoadContract";
import useLoadInitialContracts from "./hooks/useLoadInitialContracts";
import { PosibleContractStatuses } from "./types";

type Props = {
  id: ID;
};

type TButtons = {
  // eslint-disable-next-line no-unused-vars
  [_ in PosibleContractStatuses]: JSX.Element;
};

const ContractEditForm = ({ id }: Props) => {
  const contractLastSearch = useSelector((state: StateProps) => state.LastSearchParams.contract);
  const [status, setStatus] = useState<PosibleContractStatuses>("LOADING");

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError,
    setValue,
    resetField
  } = useForm();

  const { isLoading, error, initialContractsIds, data } = useLoadContract(id, reset, setStatus);

  const { initialContractsList } = useLoadInitialContracts(initialContractsIds);
  const edit = useEditContract(id, contractLastSearch, watch("contract_number"), setError);
  const del = useDeleteContract(id, contractLastSearch, watch("contract_number"));

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      const submitStatus = +submitter.value as 1 | 2;

      const {
        client,
        contractor,
        subject_type,
        contract_number,
        contract_date,
        creatives_registrar
      } =
        watch("contract_type") == 4
          ? {
              client: watch("self_ad_participant") as Contract["client"],
              contractor: watch("self_ad_participant") as Contract["contractor"],
              subject_type: 1 as Contract["subject_type"], // 'Иное'
              contract_number: "" as Contract["contract_number"],
              contract_date: format(new Date(), "yyyy-MM-dd") as Contract["contract_date"],
              creatives_registrar: 2 as Contract["creatives_registrar"] // 'Исполнитель'
            }
          : {
              client: watch("client") as Contract["client"],
              contractor: watch("contractor") as Contract["contractor"],
              subject_type: watch("subject_type") as Contract["subject_type"],
              contract_number: (watch("contract_number") || "") as Contract["contract_number"],
              contract_date: watch("contract_date") as Contract["contract_date"],
              creatives_registrar: watch("creatives_registrar") as Contract["creatives_registrar"]
            };

      const is_agent_acting_for_publisher =
        watch("is_agent_acting_for_publisher") !== undefined
          ? !!watch("is_agent_acting_for_publisher")
          : undefined;

      edit({
        id,
        contract: {
          ...omit(watch(), [
            "noNumber",
            "self_ad_participant",
            "id",
            "contractor_ord",
            "client_ord"
          ]),
          vat_included: !!(watch("vat_included") as 0 | 1 | undefined),
          is_agent_acting_for_publisher,
          client,
          contractor,
          subject_type,
          contract_number,
          contract_date,
          creatives_registrar,
          status: submitStatus
        }
      });
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorComponent error={error.message} status={error.response?.status} isEmbedded />;
  }

  const buttons: TButtons = {
    DRAFT: (
      <EditorFooter
        left={
          <>
            <Button
              type="submit"
              color="danger"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                del(id);
              }}
            >
              Удалить
            </Button>
            <Button type="submit" color="primary" disabled={isLoading} value="2">
              Сохранить черновик
            </Button>
          </>
        }
        right={
          <>
            <Button
              href={`#/contract/${contractLastSearch}`}
              disabled={isLoading}
              tag="a"
              color="primary"
              outline
            >
              Отменить
            </Button>
            <Button type="submit" color="danger" value="1" disabled={isLoading}>
              Добавить
            </Button>
          </>
        }
      />
    ),
    ACTIVE: (
      <EditorFooter
        right={
          <>
            <Button
              href={`#/contract/${contractLastSearch}`}
              disabled={isLoading}
              tag="a"
              color="primary"
              outline
            >
              Отменить
            </Button>
            <Button type="submit" color="danger" value="1" disabled={isLoading}>
              Сохранить
            </Button>
          </>
        }
      />
    ),
    LOADING: <EditorFooter />
  };

  return (
    <div>
      <h1>Редактирование договора {watch("id")}</h1>

      <AttributesDropdown
        title={
          <div className="d-flex justify-content-between align-items-center">
            Статус договора:{" "}
            {data?.data ? StatusInline({ data: data.data, dictionary: contract_status }) : ""}
          </div>
        }
      >
        <ContractAttributesBody data={data?.data} />
      </AttributesDropdown>

      <ContractForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons[status]}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        contractLoading={isLoading}
        isDraft={status === "DRAFT"}
        InitialContractsValues={initialContractsList}
      />
    </div>
  );
};

export default ContractEditForm;
