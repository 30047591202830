import axios from "axios";
import { CreativeV2 } from "models";
import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import getSearchParams from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsCreative extends TSearchParamsPropsNoFilter {
  filter?: TCreativeFilter;
}

export const getCreativeV2List = (props?: IPropsCreative): Promise<APIListData<CreativeV2>> => {
  return axios
    .get(
      props === undefined ? `/api/v2/creative/` : `/api/v2/creative/?${getSearchParams(props)}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        timeout: 1000
      }
    )
    .then((res) => {
      return res.data as Promise<APIListData<CreativeV2>>;
    });
};
