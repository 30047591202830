import { ID } from "models";
import { useMutation } from "react-query";
import { NavigateFunction } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";
import {deleteCreativeV2} from "api/creative_v2_delete";

export const useDeleteCreative = ({
  id,
  listingLink,
  navigate
}: {
  id: ID;
  listingLink: string;
  navigate: NavigateFunction;
}) => {
  const { mutate: onCreativeRemove } = useMutation({
    mutationFn: deleteCreativeV2,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        href: listingLink,
        navigate,
        successMessage: `Креатив [${id}] удален`
      })
  });
  return onCreativeRemove;
};
