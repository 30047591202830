/* eslint-disable no-unused-vars */
import { format } from "date-fns";
import syncStatusDict from "dictionaries/sync_status.json";
import { DefaultSyncStatus } from "models";
import { useState } from "react";
import { Button } from "reactstrap";
import { SyncStatusKeys } from "types/keys";

export const SyncStatusText: {
  [k in SyncStatusKeys]: (
    _account?: string,
    _ordAccount?: string,
    _error_message?: string,
    _synced_at?: string
  ) => string;
} = {
  SYNC_NOT_NEEDED: () => `<p class='mb-0'>${syncStatusDict["SYNC_NOT_NEEDED"]}</p>`,
  READY_TO_SYNC: () => `<p class='mb-0'>${syncStatusDict["READY_TO_SYNC"]}</p>`,
  IS_SYNCHRONIZING: (ordAccount, _error_message, synced_at, account) =>
    `<p class="mb-0">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:mm:ss")} ` : ""
    }${syncStatusDict["IS_SYNCHRONIZING"]} на аккаунт ${account}</p>`,
  SYNCHRONIZED: (ordAccount, _error_message, synced_at, account) =>
    `<p class="mb-0 text-success">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:mm:ss")} ` : ""
    }${syncStatusDict["SYNCHRONIZED"]} на аккаунт ${account}</p>`,
  SYNC_ERROR: (ordAccount, error_message, synced_at, account) =>
    `<p class="mb-0 text-danger">${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:mm:ss")} ` : ""
    }${syncStatusDict["SYNC_ERROR"]} на аккаунт ${account}: ${error_message?.replaceAll(";", ";<br />")}</p>`,
  TEMPORARY_SYNC_ERROR: (ordAccount, error_message, synced_at, account) =>
    `<p class='mb-0 text-warning'>${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:mm:ss")} ` : ""
    }${syncStatusDict["TEMPORARY_SYNC_ERROR"]} на аккаунт ${account}: ${error_message}</p>`,
  REGISTERED: (ordAccount, _error_message, synced_at, account) =>
    `<p class='mb-0 text-success'>${syncStatusDict["REGISTERED"]} на аккаунт ${account}</p>`,
  PARTIALLY_REGISTERED: (ordAccount, _error_message, synced_at, account) =>
    `<p class='mb-0 text-success'>${syncStatusDict["PARTIALLY_REGISTERED"]} на аккаунт ${account}</p>`
};

const getPreviewParagraph = (paragraphs: string[]): string | null => {
  if (paragraphs.at(0)!.split("<br />").length > 1)
    return paragraphs.at(0)!.split("<br />").at(0)!.concat("...</p>");

  if (paragraphs.length > 1) return paragraphs.at(0)!;

  return null;
};

export const AttributesSyncStatus = ({
  syncStatus,
  title = 'Ответ ЕРИР'
}: {
  syncStatus?: DefaultSyncStatus[] | undefined;
  title?: string;
}) => {
  const [showAll, setShowAll] = useState(false);

  if (!(syncStatus && syncStatus.length)) return null;

  const paragraphs = syncStatus.map(({ status, synced_at, ord, error_message, account, status_dt }) =>
    status ? SyncStatusText[status](ord, error_message, (synced_at || status_dt), account) : ""
  );

  const previewParagraph = getPreviewParagraph(paragraphs);

  return (
    <tr>
      <td>{title}</td>
      <td>
        {previewParagraph && !showAll ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: previewParagraph }} />
            <Button onClick={() => setShowAll(true)} color="link" className="p-0">
              Показать еще
            </Button>
          </>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: paragraphs.join("") }} />
            {previewParagraph ? (
              <Button onClick={() => setShowAll(false)} color="link" className="p-0">
                Скрыть
              </Button>
            ) : null}
          </>
        )}
      </td>
      <td></td>
    </tr>
  );
};
