import { CreativeGroupV2, ID } from "models";
import { APIData } from "./types";

export const getCreativeGroupV2Item = (id: ID): Promise<APIData<CreativeGroupV2>> => {
  return fetch(`/api/v2/creative_group/${id}/`, {
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  })
    .then((res) => {
      const result = res.json() as Promise<APIData<CreativeGroupV2>>;
      return result;
    })
    .then((res) => {
      if (res.status === false) {
        throw new Error(res.error_message);
      }
      return res;
    });
};
