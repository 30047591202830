export const useCurrentBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const isIE = userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("rv:") > -1;
  const isChrome = userAgent.indexOf('Chrome') > -1;
  const isSafari = !isChrome && userAgent.indexOf("Safari") > -1;

  return {
    isIE, isChrome, isSafari
  }
};
