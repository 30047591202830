import { CreativeV2, MediaDataV2, TextDataV2 } from "models";
import {FC, JSX, ReactElement, useState} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { TAttachmentType } from "types/AttachmentsTypes";
import { Pagination } from "ui/Pagination";
import ProgressLoader from "ui/ProgressLoader";
import getLastPageNumber from "utils/getLastPageNumber";
import BodyTextData from "Components/CreativeList/components/preview/bodyTextData";
import BodyMediaData from "Components/CreativeList/components/preview/bodyMediaData";

interface Props {
  creative?: CreativeV2;
  isOpen: boolean;
  toggle: () => void;
}

export const CreativePreviewModal: FC<Props> = ({
  creative,
  isOpen,
  toggle
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const files_length = (creative?.medias || []).length;
  const texts_length = (creative?.texts || []).length;
  const items_length = files_length + texts_length;

  const item_type = (currentPage < files_length)
    ? "file" as TAttachmentType
    : (
      currentPage < items_length
        ? "text" as TAttachmentType
        : null
    );

  const onClose = () => {
    toggle();
    setCurrentPage(0);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setLoading(true);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered className="p-0 modal-shadow-none">
      <ModalHeader toggle={onClose}>Просмотр креативов</ModalHeader>
      <ModalBody className="d-block justify-content-center overflow-auto p-0">
        {loading ? <ProgressLoader /> : <div style={{ height: "5px", marginBottom: "5px" }} />}
        <div style={{ height: "500px", marginBottom: "10px", paddingInline: "10px" }}>
          {item_type === "file" && (
            <BodyMediaData
              item={(creative?.medias || [])[currentPage]}
              creativeId={creative?.id}
              onLoad={() => setLoading(false)}
            />
          )
          }
          {item_type === "text" && (
            <BodyTextData
              item={(creative?.texts || [])[currentPage - files_length]}
              onLoad={() => setLoading(false)}
            />
          )
          }
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Pagination
          currentPage={currentPage}
          lastPageNumber={getLastPageNumber(items_length || 0, 1)}
          setCurrentPage={onPageChange}
        />
      </ModalFooter>
    </Modal>
  );
};
